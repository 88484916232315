import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentCode = makeShortcode("ComponentCode");
const ComponentDocs = makeShortcode("ComponentDocs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ComponentCode name="Simple date picker" component="date-picker" variation="date-picker" hasLightVersion hasReactVersion hasVueVersion="datepicker--simple" hasAngularVersion="?path=/story/datepickerinput--simple" codepen="ZZQbVV" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentCode name="Single date picker" component="date-picker" variation="date-picker--single" hasLightVersion hasReactVersion hasVueVersion="datepicker--single" hasAngularVersion="?path=/story/date-picker--single" codepen="ZZQbPw" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentCode name="Range date picker" component="date-picker" variation="date-picker--range" hasLightVersion hasReactVersion hasVueVersion="datepicker--range" hasAngularVersion="?path=/story/date-picker--range" codepen="yreYrQ" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentCode name="Single no label date picker" component="date-picker" variation="date-picker--single-no-label" hasLightVersion hasReactVersion codepen="OGMMGr" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentCode name="Time picker" component="time-picker" variation="time-picker" hasLightVersion hasReactVersion hasVueVersion="timepicker--default" codepen="jRWqNO" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentDocs component="date-picker" mdxType="ComponentDocs"></ComponentDocs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      